import React from "react";
import { Box, Text, Heading, Divider, Paragraph, Link } from "theme-ui";
import { isMobile, IStylesObject } from "../../theme/theme";
import { ITextModule } from "../../types";
import RichTextRenderer from "../atoms/RichTextRenderer";

const styles: IStylesObject = {
  headingWrapper: {
    display: "flex",
    pt: "2rem",
  },
  dot: {
    color: "red",
  },
  textModule: {
    marginBottom: "2rem",
  },
};

type IProps = {
  module: ITextModule;
};

export default function TextModule(props: IProps) {
  return (
    <Box data-type="TextModule" sx={styles.textModule}>
      <Box sx={styles.headingWrapper}>
        <Text variant="h2" sx={styles.dot}>
          .
        </Text>
        <Heading variant="h2" as="h2">
          {props.module.title}
        </Heading>
      </Box>
      <Box variant="bodyText">
        <RichTextRenderer doc={props.module.content.json} />
      </Box>
    </Box>
  );
}
