import React from "react";
import { Image, Box, Heading, Text } from "theme-ui";
import { isMobile, IStylesObject } from "../../theme/theme";
import { ICourseModule } from "../../types";
import RichTextRenderer from "../atoms/RichTextRenderer";

const styles: IStylesObject = {
  courseModule: {},
  course: {
    marginBottom: "2rem",
  },
};

type IProps = {
  module: ICourseModule;
};

export default function CourseModule(props: IProps) {
  return (
    <Box data-type="CourseModule" sx={styles.courseModule}>
      {props.module.coursesCollection.items.map((item) => (
        <Box key={item.sys.id} sx={styles.course}>
          <Heading as="h4" variant="h4">
            {item.name}
          </Heading>
          <Image src={`${item.heroImage.url}?q=50`} />
          <Box>
            <Text variant="bodyText">
              <RichTextRenderer doc={item.description.json} />
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
