import React from "react";
import { IStylesObject } from "../../theme/theme";
import {
  IFaqModule,
  ICourseModule,
  ICrewModule,
  IPage,
  ISingleImageModule,
  ITextModule,
  TAllModules,
  IIframeModule,
} from "../../types";
import CourseModule from "../modules/CourseModule";
import CrewModule from "../modules/CrewModule";
import FaqModule from "../modules/FaqModule";
import IframeModule from "../modules/IframModule";
import SingleImageModule from "../modules/SingleImageModule";
import TextModule from "../modules/TextModule";
import { ModuleWrapper } from "../ModuleWrapper/ModuleWrapper";

const styles: IStylesObject = {
  Page: {},
};

const ModuleRenderer = ({ module }: { module: TAllModules }): JSX.Element => {
  const getModule = (module: TAllModules) => {
    switch (module.__typename) {
      case "contentful_IframeModule": 
        return <IframeModule module={module as IIframeModule} />
      case "contentful_CourseContent":
        return <CourseModule module={module as ICourseModule} />;
      case "contentful_TextModule":
        return <TextModule module={module as ITextModule} />;
      case "contentful_SingleImageModule":
        return <SingleImageModule module={module as ISingleImageModule} />;
      case "contentful_CrewContent":
        return <CrewModule module={module as ICrewModule} />;
      case "contentful_FaqContent":
        return <FaqModule module={module as IFaqModule} />;
      default:
        return (
          <div>
            Missing Implementation for Module <em>{module.__typename}</em>
          </div>
        );
    }
  };

  return <>{getModule(module)}</>;
};

export const Page = (props: IPage) => {
  const modules = props?.modules;

  return (
    <ModuleWrapper>
      {modules?.map((module: any, i: number) => (
        <ModuleRenderer
          key={module.name + `${i}`}
          module={module}
        ></ModuleRenderer>
      ))}
    </ModuleWrapper>
  );
};

export default Page;
