import React from "react";
import { Box } from "theme-ui";
import { IStylesObject } from "../../theme/theme";
import { IIframeModule } from "../../types";

const styles: IStylesObject = {};

type IProps = {
  module: IIframeModule;
};

export default function IframeModule(props: IProps) {
  return (
    <Box data-type="IframeModule">
      {props.module?.iframe?.link ? (
        <iframe
          style={{
            width: "100%",
            height: "100vh",
          }}
          src={props.module.iframe.link}
          frameBorder={0}
          sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups"
        />
      ) : null}
    </Box>
  );
}
