import React, { ReactNode, useEffect } from "react";
import { Container, ThemeUIStyleObject } from "theme-ui";
import { isDesktop, isMobile, IStylesObject } from "../../theme/theme";
import { gsap, Power2 } from "gsap";

export interface IPropsModuleWrapper {
  sx?: ThemeUIStyleObject;
  children: ReactNode;
}
const styles: IStylesObject = {
  container: {
    p: "10vh 1rem",
    bg: "white",
    minHeight: "100vh",
    position: "absolute",
    top: 0,
    right: 0,
    maxWidth: ["100vw", "50vw"],
  },
};

const animatedContainerClassName = 'anim-container'

export const ModuleWrapper = ({
  children,
  sx,
}: IPropsModuleWrapper): JSX.Element => {
  // deactivated for now
  // useEffect(() => {
  //   gsap.set(`.${animatedContainerClassName}`, {
  //     x: "100vw",
  //   });
  //   let tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".line",
  //       start: "center center",
  //       markers: true,
  //     },
  //   });
  //   tl.to(`.${animatedContainerClassName}`, {
  //     x: 0,
  //     duration: 1,
  //     ease: Power2.easeOut,
  //   });
  // });
  return (
    <Container className={animatedContainerClassName} sx={{ ...styles.container, ...sx }}>
      {children}
    </Container>
  );
};
