import React from "react";
import { Link } from "theme-ui";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, Document, BLOCKS } from "@contentful/rich-text-types";

type IProps = {
  doc: Document;
};

export default function RichTextRenderer(props: IProps) {
  return (
    <>
      {documentToReactComponents(props.doc, {
        renderNode: {
          [INLINES.HYPERLINK]: (node, children) => <Link target="blank" href={node.data.uri}>{children}</Link>,
          // [INLINES.EMBEDDED_ENTRY]: (node, children) => <div>EMBEDDED_ENTRY</div>,
          // [INLINES.ASSET_HYPERLINK]: (node, children) => <div>ASSET_HYPERLINK</div>,
          // [INLINES.ENTRY_HYPERLINK]: (node, children) => <div>ENTRY_HYPERLINK</div>,
          // [INLINES.EMBEDDED_RESOURCE]: (node, children) => <div>EMBEDDED_RESOURCE</div>,
          // [INLINES.RESOURCE_HYPERLINK]: (node, children) => <div>RESOURCE_HYPERLINK</div>,
          // [BLOCKS.EMBEDDED_ASSET]: (node, children) => <img className="img-fluid" src={node.data.target.fields.file.url} />,
          // [BLOCKS.EMBEDDED_ASSET]: (node, children) => <div>{JSON.stringify(node)}</div>,
        },
      })}
    </>
  );
}
