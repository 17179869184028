import * as React from "react";
import { theme } from "../theme/theme";
import { ThemeProvider } from "theme-ui";
import "../index.css";
import { Page } from "../components/Page/Page";
import { graphql } from "gatsby";
import NavBar from "../components/NavBar/Navbar";
import Footer from "../components/Footer/Footer";
import { NavBarItem } from "../types";
import HomeModule from "../components/modules/HomeModule";

type IPageTemplate = {
  pageContext: {
    id: string;
    navBarItems: NavBarItem[];
  };
  data: {
    contentful: any;
  };
};

function App({ data, pageContext }: IPageTemplate): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <NavBar
        currentPageId={pageContext.id}
        navBarItems={pageContext.navBarItems}
      />
      <HomeModule />
      {data.contentful?.page?.modulesCollection?.items ? (
        <Page modules={data.contentful?.page?.modulesCollection?.items}></Page>
      ) : (
        <div>No Page found</div>
      )}
      <Footer />
    </ThemeProvider>
  );
}

export const getPage = graphql`
  query getPage($id: String!) {
    contentful {
      page(id: $id) {
        name
        modulesCollection {
          items {
            __typename
            ... on contentful_IframeModule {
              name
              sys {
                id
              }
              iframe { name, link }
            }
            ... on contentful_SingleImageModule {
              name
              sys {
                id
              }
              caption
              image {
                url
              }
            }
            ... on contentful_TextModule {
              sys {
                id
              }
              name
              title
              content {
                json
              }
            }
            ... on contentful_CrewContent {
              sys {
                id
              }
              name
              membersCollection(limit: 30) {
                items {
                  sys {
                    id
                  }
                  lastName
                  firstName
                  profession
                  portraitImage {
                    url
                  }
                  shortDescription {
                    json
                  }
                }
              }
            }
            ... on contentful_FaqContent {
              sys {
                id
              }
              name
              faqsCollection {
                items {
                  sys {
                    id
                  }
                  question
                  answer {
                    json
                  }
                }
              }
            }
            ... on contentful_CourseContent {
              name
              sys {
                id
              }
              coursesCollection(limit: 30) {
                items {
                  sys {
                    id
                  }
                  name
                  heroImage {
                    url
                  }
                  description {
                    json
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default App;
