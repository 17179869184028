import React from "react";
import { Box, Image } from "theme-ui";
import { ISingleImageModule } from "../../types";

type IProps = {
  module: ISingleImageModule;
};

export default function SingleImageModule(props: IProps) {
  return (
    <Box data-type="SingleImageModule">
      {props.module.name}
      <Image src={props.module.image.url} />
    </Box>
  );
}
