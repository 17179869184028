import React from "react";
import { Box, Image, Text } from "theme-ui";
import { isDesktop, isMobile, IStylesObject } from "../../theme/theme";
import { ICrewModule } from "../../types";
import RichTextRenderer from "../atoms/RichTextRenderer";

const styles: IStylesObject = {
  crewModule: {
    /*     display: "grid",
    gridGap: 3,
    gridAutoFlow: "row",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
 */
  },
  item: {
    position: "relative",
    display: "flex",
    width: "100%",
    overflow: "hidden",
    zIndex: "10",
  },
  imageWrapper: {
    width: "50%",
    display: "flex",
    alignItems: ["start", "center"],
    justifyContent: "center",
  },
  image: {
    aspectRatio: "1",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    transition: "transform .6s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
    borderRadius: "50%",
    width: "80%",
    m: "5vw 1vw",
  },
  textWrapper: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  name: {
    pb: "1rem",
  },
  profession: {
    fontSize: "1rem",
    fontWeight: 700,
    color: 'red',
  }
};

type IProps = {
  module: ICrewModule;
};

const calculatedStyles: any = {
  item: (i: number) => {
    return {
      flexDirection: i % 2 == 0 ? "row" : "row-reverse",
    };
  },
  textWrapper: (i: number) => {
    return {
      alignItems: i % 2 == 0 ? "start" : "end",
    };
  },
};

export default function CrewModule(props: IProps) {
  return (
    <Box data-type="CrewModule" sx={styles.crewModule}>
      {props.module.membersCollection.items.map((member, i) => (
        <Box
          key={member.sys.id + "_" + i}
          sx={{ ...styles.item, ...calculatedStyles.item(i) }}
        >
          <Box sx={styles.imageWrapper}>
            <Box
              sx={styles.image}
              style={{
                backgroundImage: `url('${member.portraitImage.url}?h=500')`,
              }}
            />
          </Box>
          <Box
            sx={{ ...styles.textWrapper, ...calculatedStyles.textWrapper(i) }}
          >
            <Text variant="h3" sx={styles.name}>
              {member.firstName} {member.lastName}
            </Text>
            {member.profession
              ? 
                <Text variant="bold" sx={styles.profession}>
                  {member.profession.join(', ')}
                </Text>
              : null}

            {/* Don't know if the description text is needed */}
            {isDesktop() ? (
              <RichTextRenderer doc={member.shortDescription.json} />
            ) : null}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
