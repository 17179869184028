import React from "react";
import { Box, Text } from "theme-ui";
import { isMobile, IStylesObject } from "../../theme/theme";
import { IFaqModule } from "../../types";
import RichTextRenderer from "../atoms/RichTextRenderer";

const styles: IStylesObject = {
  faqModule: {},
  faq: {
    padding: "1rem 0",
  },
  mobileFaq: {
    padding: "1rem",
  },
  answer: {
    "> p:first-of-type": {
      marginTop: 0,
    },
    "> p:last-child": {
      marginBottom: 0,
    },
  },
};

type IProps = {
  module: IFaqModule;
};

export default function FaqModule(props: IProps) {
  return (
    <Box data-type="FaqModule" sx={styles.faqModule}>
      {props.module.faqsCollection.items.map((faq) => (
        <Box key={faq.sys.id} sx={isMobile() ? styles.mobileFaq : styles.faq}>
          <Text variant="bold">{faq.question}</Text>
          <Text sx={styles.answer} variant="bodyText">
            <RichTextRenderer doc={faq.answer.json} />
          </Text>
        </Box>
      ))}
    </Box>
  );
}
